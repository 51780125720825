
function setMainLayoutBodyCssClass(){
    $('body').removeClass();
    $('body').css("background-image", "");
    $('body').addClass('header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-enabled');
}

function setBgCoverCenterLayout(){
    $('body').removeClass();
    $('body').css("background-image", "");
    $('body').addClass('auth-bg bgi-size-cover bgi-position-center bgi-no-repeat');
    $('body').css('background-image', "url('./assets/media/auth/bg3.jpg')");
}